<template>
    <div id="div">
        <div class="center_content">
            <div style="padding:15px;background:#FFF;">
                <!-- 	        	<el-form label-width="120px" size="medium" label-position="left"> -->

                <div class="home-balance-head-title">
                    <span>编辑话题</span>
                </div>

                <el-form :model="topic"  ref="topic" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="话题名称" :rules="{ required: true, message: '话题名称不能为空', trigger: 'blur'}">
                        <el-input disabled="disabled" v-model="topic.name" :show-word-limit="true" maxlength="50" minlength="4" placeholder="话题名称"></el-input>
                    </el-form-item>


                    <el-form-item label="话题描述" :rules="{ required: true, message: '话题描述不能为空', trigger: 'blur'}">
                        <el-input v-model="topic.detail" type="textarea" :rows="5" :autosize="{ minRows: 5, maxRows: 15 }"  :show-word-limit="true" maxlength="10000" minlength="5" placeholder="话题描述"></el-input>
                    </el-form-item>


                    <el-form-item label="话题分类" :rules="{ required: true, message: '话题分类不能为空', trigger: 'blur'}" >
                        <el-cascader v-model="topic.typeid" :options="type_options" :props="{ expandTrigger: 'hover' }" placeholder="请选择分类" ></el-cascader>
                    </el-form-item>


                    <el-form-item label="上传话题背景图" :rules="{ required: true, message: '话题背景图不能为空', trigger: 'blur'}">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :http-request='changeUpload'>
                            <img v-if="topic.bgimg != '' " :src="topic.bgimg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="add('topic')">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="right_content">
            <div class="div">
                <div class="title_pic" style="margin-bottom:15px;padding-top: 10px;" >
                    <div class="title" style="color:#000;border-left:6px solid #031F88;padding-left:10px;">热门话题</div>
                </div>
                <div class="text">
                    <div style="overflow: hidden;height: 30px;line-height: 30px;" v-for="(item,index) in topic_list" :key="index" @click="a('/topic/info/'+item.name)" class="op sc hand">
                        <div style="float: left;width:170px; text-overflow: ellipsis;white-space: nowrap;letter-spacing:1px;cursor: pointer;"> #{{item.name}}#</div>
                        <div style="float: right;color: #a6a6a6;">{{item.clickcount}}</div>
                    </div>
                    <div style="height:20px; line-height:20px;text-align: center;margin-top: 10px;border-top: 1px solid #BBBBBB; padding-top: 15px;" v-if="topic_list.length > 0 ">
                        <span @click="a('/topic/list')" class="hand op sc">查看更多</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name:"edit",
        components: {

        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
        data () {
            return {
                topic:{
                    name:"",
                    bg_img:"",
                    detail:"",
                    type_id:0,
                },
                type_options:[],
                topic_list:[],
                topics:""
            }
        },
        props:{

        },

        created:function(){
            this.get_type_list();
        },

        mounted: function () {
            this.topics = this.$route.params.topic;
            this.getTopicInfo();
            this.getHotTopicList();
        },

        methods:{
            getTopicInfo:function(){
                var that = this;
                that.newApi.getTopicInfo({name:that.topics}).then((ret) => {
                    that.topic = ret.data;
                })
            },

            add:function(){
                var that = this;
                if (that.topic.name == "") {
                    that.utils.err("请输入话题名称");return;
                }
                if (that.topic.detail == "") {
                    that.utils.err("请输入话题描述");return;
                }
                if (that.topic.type_id == 0) {
                    that.utils.err("请选择专业分类");return;
                }

                if (that.topic.bg_img == "") {
                    that.utils.err("请上传话题图片");return;
                }

                var params = {};
                params.id = that.topic.id;
                params.name = that.topic.name;
                params.detail = that.topic.detail;
                params.bgImg = that.topic.bgimg;

                if(Array.isArray(that.topic.typeid)){   //true
                    params.typeId = that.topic.typeid[1];
                }else{
                    params.typeId = that.topic.typeid;
                }
                that.newApi.editTopic(params).then((ret) => {
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.a('/topic/info/'+that.topic.name);
                    }

                })
            },
            get_type_list:function(){
                var that = this;
                that.newApi.getTypeList({lv:2}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        var type_options = ret.data;
                        for (var i = 0; i < type_options.length; i++) {
                            for (var j = 0; j < type_options[i].children.length; j++) {
                                type_options[i].children[j].check = false;
                            }
                        }
                        that.type_options = type_options;
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            getHotTopicList(){
                var that = this;
                var params = {};
                that.newApi.getHotTopicList(params).then((res) =>{
                    that.topic_list = res.data.slice(0,20);
                });
            },
            changeUpload(params) {
                var file = params.file;
                var that = this;
                const isLt5M = file.size / 1024 / 1024 < 20
                if (!isLt5M) {
                    this.$message.error('上传文件大小不能超过 20MB!')
                    return false
                }
                this.utils.upload(file,function(url){
	                if (!url){
		                return false;
	                }
                    that.topic.bgimg = url;
                })
            },
        }
    }

</script>
<style type="text/css">
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }
    .avatar {
        width: 80px;
        height: 80px;
        display: block;
    }
</style>
<style scoped>
    #div{
        overflow:auto;
        background:#ffffff;
    }
    .center_content{
        width:700px; float:left; margin:0 5px;margin-left:calc((100% - 1400px)/2 + 190px);
    }
    .right_content{
        width:280px; float:left; margin:0 0 0 5px;background: #8a8a8a;
    }
    .home-balance-head-title{
        font-size:18px;color:#333;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;font-weight:700;margin:0 10px 20px 10px;
    }

    .types{
        padding:10px;background:#FFF; overflow:auto;
    }
    .types .type {
        float:left; width:16.6%;text-align:center; font-size:14px;
    }

    .sub_types{
        overflow:auto; background:#FFF;padding:10px;z-index:9999;
        width:800px;
        margin:100px auto;
    }
    .sub_types .sub_type{
        float:left; margin:5px; padding:2px 5px;
        border-radius:4px; background:#f1f1f1;border:1px solid #f1f1f1;
    }


    .sub_type.active{
        color:#deb776; background:#fff; border:1px solid #deb776;
    }

    /*热门话题*/
    .div{
        background:#FFF;position:relative;
    }
    .div .title_pic{
        overflow:hidden;
    }
    .div .title_pic .title{
        color:#031F88;font-size:20px;font-weight:700;
        max-height:50px;line-height:25px; float:left;padding:0px 15px;
    }
    .div .title_pic .pic{
        float:right;padding-right: 15px;
    }
    .div .text{
        color:#666; margin-top:10px;
        white-space: pre-wrap;
        overflow: hidden;
        padding:0px 15px;
        /*max-height:88px;overflow:hidden;line-height:22px; position:relative;*/
    }

</style>
